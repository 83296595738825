import React from 'react';
import { useForm, Resolver, SubmitHandler, DefaultValues } from 'react-hook-form';

type VerticalFromProps<TFormValues> = {
    defaultValues?: DefaultValues<TFormValues>;
    resolver?: Resolver<any>;
    children?: React.ReactNode;
    onSubmit: SubmitHandler<any>;
    formClass?: string;
};

const VerticalForm = <TFormValues extends Record<string, any> = Record<string, any>>({
  defaultValues,
  resolver,
  children,
  onSubmit,
  formClass,
}: VerticalFromProps<TFormValues>) => {
  /*
     * form methods
     */
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<TFormValues>({ defaultValues, resolver });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={formClass} noValidate>
      {Array.isArray(children)
        ? children.map((child) => {
          return child.props && child.props.name
            ? React.createElement(child.type, {
              ...{
                ...child.props,
                ...register(child.props.name, {
                  ...child.props,
                  onChange(event) {
                    child.props.onChange(event);
                  }}),
                register,
                key: child.props.name,
                errors,
                control,
              },
            })
            : child;
        })
        : children}
    </form>
  );
};

export default VerticalForm;
